import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { effectHooks } from '../../helpers';
import { ExternalServicesMappingService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class ExternalServicesMappingStoreEffects {
  constructor(
    private dataService: ExternalServicesMappingService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertyId, externalServiceId }) =>
        this.dataService.load(propertyId, externalServiceId).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              mappings: response.data,
              externalServiceId,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  download$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadRequest),
      switchMap(({ propertyId, externalServiceId }) =>
        this.dataService.download(propertyId, externalServiceId).pipe(
          map((response: IResponseSuccess) => {
            this.notifications.done('download_mappings_done');
            return fromActions.downloadSuccess({
              mappings: response.data,
              externalServiceId,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.downloadFailure(error));
          }),
        ),
      ),
    ),
  );

  export$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.exportRequest),
      switchMap(({ propertyId, externalServiceId, from, to }) =>
        this.dataService.export(propertyId, externalServiceId, from, to).pipe(
          map(() => {
            this.notifications.done('export_success');
            return fromActions.exportSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.exportFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request }) =>
        this.dataService.update(request).pipe(
          map(() => {
            this.notifications.updateSuccess('mappings');
            return fromActions.updateSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  setScheduling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setSchedulingRequest),
      switchMap(({ request, ...hooks }) =>
        this.dataService.setScheduling(request).pipe(
          effectHooks(hooks),
          map(() => {
            this.notifications.success('notifications.generic_success');
            return fromActions.setSchedulingSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.setSchedulingFailure(error));
          }),
        ),
      ),
    ),
  );
}
