import { ExternalServiceMapping } from '../../models/objects/external-service-mapping';

export interface State {
  data: {
    [externalServiceId: number]: ExternalServiceMapping[];
  };
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  data: {},
  isLoading: false,
  error: null,
};
