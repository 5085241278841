import { createAction, props } from '@ngrx/store';

import { Hook } from '../../helpers';
import { SetExternalServiceSchedulingRequest } from '../../models';
import { ExternalServiceMapping } from '../../models/objects/external-service-mapping';
import { UpdateExternalServicesMappingRequest } from '../../models/requests/update-external-services-mapping-request';

export const loadRequest = createAction(
  '[External Services Mapping] Load Request',
  props<{ externalServiceId: number; propertyId: number }>(),
);
export const loadSuccess = createAction(
  '[External Services Mapping] Load Success',
  props<{ mappings: ExternalServiceMapping[]; externalServiceId: number }>(),
);
export const loadFailure = createAction(
  '[External Services Mapping] Load Failure',
  props<{ error: any }>(),
);

export const downloadRequest = createAction(
  '[External Services Mapping] Download Request',
  props<{ externalServiceId: number; propertyId: number }>(),
);
export const downloadSuccess = createAction(
  '[External Services Mapping] Download Success',
  props<{ mappings: ExternalServiceMapping[]; externalServiceId: number }>(),
);
export const downloadFailure = createAction(
  '[External Services Mapping] Download Failure',
  props<{ error: any }>(),
);

export const exportRequest = createAction(
  '[External Services Mapping] Export Request',
  props<{
    propertyId: number;
    externalServiceId: number;
    from: string;
    to: string;
  }>(),
);
export const exportSuccess = createAction(
  '[External Services Mapping] Export Success',
);
export const exportFailure = createAction(
  '[External Services Mapping] Export Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[External Services Mapping] Update Request',
  props<{
    request: UpdateExternalServicesMappingRequest;
  }>(),
);
export const updateSuccess = createAction(
  '[External Services Mapping] Update Success',
);
export const updateFailure = createAction(
  '[External Services Mapping] Update Failure',
  props<{ error: any }>(),
);

export const setSchedulingRequest = createAction(
  '[External Services Mapping] Set Scheduling Request',
  props<{
    request: SetExternalServiceSchedulingRequest;
    onSuccess?: Hook;
    onFailure?: Hook;
  }>(),
);
export const setSchedulingSuccess = createAction(
  '[External Services Mapping] Set Scheduling Success',
);
export const setSchedulingFailure = createAction(
  '[External Services Mapping] Set Scheduling Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[External Services Mapping] Reset State',
);
