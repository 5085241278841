import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    data: null,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { mappings, externalServiceId }) => ({
    ...state,
    data: {
      ...state,
      [externalServiceId]: mappings,
    },
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.downloadRequest, (state) => ({
    ...state,
    data: null,
    isLoading: true,
    error: null,
  })),
  on(fromActions.downloadSuccess, (state, { mappings, externalServiceId }) => ({
    ...state,
    data: {
      ...state,
      [externalServiceId]: mappings,
    },
    isLoading: false,
    error: null,
  })),
  on(fromActions.downloadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.exportRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.exportSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.exportFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function externalServicesMappingReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
