import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import { TokenService } from '../core/services/token.service';
import { RootState } from '../root-store/root-state';
import { UserMeStoreSelectors } from '../root-store/user-me-store';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard  {
  constructor(
    private store: Store<RootState>,
    private router: Router,
    private tokenService: TokenService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const { role, fromToken = false } = next.data;
    return this.store.pipe(
      select(UserMeStoreSelectors.selectData),
      filter((user) => !!user),
      withLatestFrom(this.store),
      debounceTime(50),
      map(([user, store]) => {
        /**
         * Serve per gestire le rotte con l'impersonate, un pò brutto però
         */
        const isUserAdmin = !!this.tokenService.isAdmin();
        if (role === 'admin' && fromToken && isUserAdmin) {
          return true;
        }

        const { permissions, roles } = user;

        const currentUser = get(permissions, 'currentUser', []) as any[];

        const { core } = store as any;

        const { selectedProperty } = core;

        let isHousekeeper = false;

        if (selectedProperty && currentUser.length) {
          isHousekeeper =
            currentUser.find((cu) => cu.property_id === selectedProperty.id)
              ?.type === 'housekeeper';
        }

        let userRole = roles[0].slug;

        if (isHousekeeper) {
          userRole = 'housekeeper';
        }

        if (userRole === role) {
          return true;
        }

        let newRoute = userRole === 'admin' ? 'admin/properties' : '';

        if (isHousekeeper) {
          newRoute = 'housekeeper';
        }

        this.router.navigate(['/' + newRoute]);

        return false;
      }),
      take(1),
    );
  }
}
